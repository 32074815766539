// extracted by mini-css-extract-plugin
export var moreInfoWrapper = "MoreInfo-module--moreInfoWrapper--1s-D4";
export var moreInfoIcon = "MoreInfo-module--moreInfoIcon--2BAo_";
export var handEmoji = "MoreInfo-module--handEmoji--2bhEA";
export var bulbEmoji = "MoreInfo-module--bulbEmoji--2RT9U";
export var inviteBannerIcon = "MoreInfo-module--inviteBannerIcon--aIjHX";
export var bannerRightSection = "MoreInfo-module--bannerRightSection--3bnr-";
export var inviteCta = "MoreInfo-module--inviteCta--3QejV";
export var closeIcon = "MoreInfo-module--closeIcon--2j5BX";
export var wrapper = "MoreInfo-module--wrapper--1PcDn";
export var info = "MoreInfo-module--info--QD8Ok";
export var accountSectionBanner = "MoreInfo-module--accountSectionBanner--AH8B-";
export var text = "MoreInfo-module--text--yDM5W";