/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import * as styles from '../../styles/admin/MoreInfo.module.scss';
import closeIcon from '../../../static/images/admin/close-icon.svg';
import inviteBannerIcon from '../../../static/images/admin/invite-banner-icon.png';

function MoreInfo({
  mainContent, text, hideBanner, accountSection, setShowInviteUserModal,
}) {
  const inviteHandler = () => {
    setShowInviteUserModal(true);
  };

  return (
    <div className={styles.moreInfoWrapper}>
      {
         !accountSection && (mainContent
           ? <p className={styles.bulbEmoji} /> : <p className={styles.handEmoji} />)
      }
      {
       accountSection && (mainContent && <img className={styles.inviteBannerIcon} src={inviteBannerIcon} alt="Send icon" />)
      }
      <div className={styles.wrapper}>
        <div
          className={`${styles.info} ${accountSection ? styles.accountSectionBanner : ''}`}
        // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: text }}
        />
        <div className={styles.bannerRightSection}>
          {
            accountSection
          && (
          <button className={styles.inviteCta} type="button" onClick={inviteHandler}>
            Invite now
          </button>
          )
          }
          <div
            className={styles.closeIcon}
            role="button"
            tabIndex={0}
            onClick={hideBanner}
          >
            <img
              src={closeIcon}
              alt="close"
            />
          </div>
        </div>

      </div>
    </div>
  );
}

MoreInfo.propTypes = {
  mainContent: PropTypes.bool,
  text: PropTypes.string.isRequired,
  hideBanner: PropTypes.func.isRequired,
  accountSection: PropTypes.bool,
  setShowInviteUserModal: PropTypes.func,
};
MoreInfo.defaultProps = {
  mainContent: false,
  accountSection: false,
  setShowInviteUserModal: () => {},
};

export default MoreInfo;
