const performanceTabs = {
  activeTab: '',
  tabData: [
    {
      name: 'Learners and Groups',
      route: '/admin/performance/',
    },
    {
      name: 'Content',
      route: '/admin/performance/content/',
      beta: false,
    },
    {
      name: 'Skills Gap Analysis',
      route: '/admin/performance/skills-gap-analysis/',
      beta: true,
    },
  ],
};

export default performanceTabs;
