import { DataStore } from 'aws-amplify';
import { Course } from '../../../models/index';
import { fetchCourseById } from '../Performance/PerformanceService';

const fetchRegistrationByCourseId = async (courseId) => {
  try {
    const registrationWithCourse = await DataStore.query(Course, courseId);
    return registrationWithCourse;
  } catch (error) {
    return null;
  }
};

const calculateCourseResult = (attributes) => {
  const totalFinalAssignments = attributes?.finalAssignmentTasks;
  const totalFinalAssignmentsCompleted = attributes?.finalAssignmentTasksCompleted;
  if (totalFinalAssignments !== totalFinalAssignmentsCompleted) {
    return null;
  }

  const moduleFinalAssignments = [
    ...(attributes?.firstModuleFinalAssignmentTotalScore
      ? [{
        totalScore: attributes.firstModuleFinalAssignmentTotalScore,
        score: attributes.firstModuleFinalAssignmentScore,
      }] : []),
    ...(attributes?.secondModuleFinalAssignmentTotalScore
      ? [{
        totalScore: attributes.secondModuleFinalAssignmentTotalScore,
        score: attributes.secondModuleFinalAssignmentScore,
      }] : []),
    ...(attributes?.thirdModuleFinalAssignmentTotalScore
      ? [{
        totalScore: attributes.thirdModuleFinalAssignmentTotalScore,
        score: attributes.thirdModuleFinalAssignmentScore,
      }] : []),
    ...(attributes?.fourthModuleFinalAssignmentTotalScore
      ? [{
        totalScore: attributes.fourthModuleFinalAssignmentTotalScore,
        score: attributes.fourthModuleFinalAssignmentScore,
      }] : []),
  ];
  const totalModules = attributes?.finalAssignmentTasks;
  let sum = 0;
  moduleFinalAssignments?.forEach((assignment) => {
    if (assignment?.totalScore) {
      sum += Math.ceil((assignment?.score
        / assignment?.totalScore) * 100);
    }
  });

  return Math.floor(sum / totalModules);
};

const getEnrolledCourses = async (enrollmentsReport) => {
  const enrollments = [];
  if (enrollmentsReport?.length > 0) {
    enrollmentsReport.forEach((enrollment) => {
      const attributes = enrollment?.attributes;
      const courseResult = calculateCourseResult(attributes);
      const totalLessons = attributes?.videoTasks;
      const lessonsWatched = attributes?.videoTasksCompleted;
      const totalAssignments = attributes?.assignmentTasks
        + attributes?.finalAssignmentTasks;
      const assignmentsCompleted = attributes?.assignmentTasksCompleted
        + attributes?.finalAssignmentTasksCompleted;
      const totalTasksCompleted = lessonsWatched + assignmentsCompleted;
      const totalTasks = totalLessons + totalAssignments;
      let completions = totalTasks
        ? Math.round((totalTasksCompleted / totalTasks) * 100) : 0;
      const totalFinalAssignments = attributes?.finalAssignmentTasks;
      const totalFinalAssignmentsCompleted = attributes?.finalAssignmentTasksCompleted;
      const moduleOneScore = Math.ceil((
        attributes?.firstModuleFinalAssignmentScore
        / attributes?.firstModuleFinalAssignmentTotalScore) * 100);
      const moduleTwoScore = Math.ceil((
        attributes?.secondModuleFinalAssignmentScore
          / attributes?.secondModuleFinalAssignmentTotalScore
      ) * 100);
      const moduleThreeScore = Math.ceil((
        attributes?.thirdModuleFinalAssignmentScore
          / attributes?.thirdModuleFinalAssignmentTotalScore
      ) * 100);
      const moduleFourScore = Math.ceil((
        attributes?.fourthModuleFinalAssignmentScore
          / attributes?.fourthModuleFinalAssignmentTotalScore
      ) * 100);
      if (totalFinalAssignments && totalFinalAssignments === totalFinalAssignmentsCompleted) {
        completions = 100;
      }
      enrollments.push({
        key: enrollment?.key,
        courseName: enrollment?.course?.courseName,
        courseId: enrollment?.key,
        lessons: ` ${lessonsWatched} / ${totalLessons} `,
        assignments: `${assignmentsCompleted} / ${totalAssignments}`,
        completions,
        modules: ` ${totalFinalAssignmentsCompleted} / ${totalFinalAssignments}`,
        userId: enrollment?.userId,
        moduleOneScore: Number.isNaN(moduleOneScore) ? '' : `${moduleOneScore}%`,
        moduleTwoScore: Number.isNaN(moduleTwoScore) ? '' : `${moduleTwoScore}%`,
        moduleThreeScore: Number.isNaN(moduleThreeScore) ? '' : `${moduleThreeScore}%`,
        moduleFourScore: Number.isNaN(moduleFourScore) ? '' : `${moduleFourScore}%`,
        courseResult: courseResult ? `${courseResult}%` : '',
      });
    });
  }
  return enrollments;
};

const mergeEnrollmentsReport = async (response) => {
  if (response?.data?.length > 0) {
    const enrollmentsReport = [];
    const includedData = response?.included?.[0];

    const courseIds = response?.data.map((data) => (
      data?.relationships?.course?.data?.id
    ));

    const promises = courseIds.map((id) => (
      fetchCourseById(id)
    ));
    const courseDetails = await Promise.all(promises);

    response?.data.forEach((data) => {
      const courseData = courseDetails.find((
        course,
      ) => course?.id === data?.relationships?.course?.data?.id);
      enrollmentsReport.push({
        key: data?.id,
        attributes: data?.attributes,
        lastActive: includedData?.attributes?.lastActive,
        userName: includedData?.attributes?.name,
        course: courseData,
        userId: data?.relationships?.user?.data?.id,
      });
    });
    return enrollmentsReport;
  }
  return [];
};
export {
  fetchRegistrationByCourseId,
  getEnrolledCourses,
  mergeEnrollmentsReport,
};
