/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import * as styles from '../../styles/admin/Account.module.scss';
import AdminContext, { MoreInfoStatus } from '../../context/Admin/AdminContext';
import accountTabs from '../../accountTabs';

const TabContainer = ({ tabs, activeTab }) => {
  const { setShowMoreInfo } = useContext(AdminContext);
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(0);

  const toggleActiveTabMoreInfo = () => {
    setShowMoreInfo((prevState) => {
      if (prevState === MoreInfoStatus.DEFAULT) {
        return MoreInfoStatus.SECONDARY;
      }
      return MoreInfoStatus.DEFAULT;
    });
  };

  const isAccountTab = (currentTab) => accountTabs.tabData.some((tab) => tab.name === currentTab);
  const toggleOptions = () => {
    setIsOptionsOpen(!isOptionsOpen);
  };

  return (
    <>
      <div className={styles.tabContainer}>
        {
        Object.values(tabs)?.length !== 0
        && tabs.tabData.map((tab) => (
          <button
            key={tab.name}
            className={activeTab === tab.name ? styles.tabTextActive : styles.tabText}
            type="button"
            onClick={() => navigate(tab.route)}
          >
            <p>
              {tab.name}
              {tab?.beta ? <span>NEW</span> : null}
            </p>
            {activeTab === tab.name && isAccountTab(tab.name)
                && (
                <div
                  onClick={toggleActiveTabMoreInfo}
                  className={`library-shaw-icon-new ${styles.infoIcon}`}
                >
                  <i className="icon-new-Information-3" />
                </div>
                )}
          </button>
        ))
    }
      </div>
      { tabs.tabData && ((tabs.tabData).length > 0) && (
      <div className={styles.mobileTabSelect}>
        {/* { (tabs.tabData).length > 1
        && ( */}
        <div className={styles.selectDropdown}>
          { Object.values(tabs)?.length !== 0
          // && tabs.tabData.map((tab) => (
          && (
            <>

              <button
                onClick={toggleOptions}
                type="button"
                aria-haspopup="listbox"
                className={isOptionsOpen ? 'expanded' : ''}
                aria-expanded={isOptionsOpen}
              >
                {activeTab}
                {' '}
                <div className="library-shaw-icon-new">
                  <i className="icon-new-Dropdown-1" />
                </div>
              </button>
              <ul
                className={`options ${isOptionsOpen ? 'show' : ''}`}
                tabIndex={-1}
                role="listbox"
                aria-activedescendant={tabs.tabData[selectedOption].name}
              >
                {tabs.tabData.map((option, index) => (
                  <li
                    key={option.name}
                  >
                    <button
                      key={option.name}
                      className={activeTab === option.name ? styles.tabTextActive : styles.tabText}
                      type="button"
                      onClick={() => {
                        navigate(option.route);
                        setSelectedOption(index);
                        setIsOptionsOpen(false);
                      }}
                      tabIndex={0}
                      id={option}
                      role="option"
                      aria-selected={selectedOption === index}
                    >
                      {option.name}

                    </button>
                  </li>
                ))}
              </ul>
            </>

          // ))
          )}
        </div>
        {/* )} */}
      </div>
      )}
    </>
  );
};

TabContainer.propTypes = {
  tabs: PropTypes.objectOf(PropTypes.any).isRequired,
  activeTab: PropTypes.string.isRequired,
};
export default TabContainer;
