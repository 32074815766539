/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext } from 'react';
import AdminContext, { MoreInfoStatus } from '../../context/Admin/AdminContext';
import * as styles from '../../styles/admin/Account.module.scss';
import MoreInfo from './MoreInfo';

type Props = {
  title: string,
  moreInfo: string,
  location: any,
  accountSection: boolean,
setShowInviteUserModal: React.Dispatch<React.SetStateAction<boolean>>
}

const MoreInfoHeader:React.FC<Props> = ({
  title, moreInfo, location, accountSection, setShowInviteUserModal,
}) => {
  const { showMoreInfo, setShowMoreInfo } = useContext(AdminContext);

  const closeMoreInfoBanner = () => {
    setShowMoreInfo(MoreInfoStatus.DEFAULT);
  };
  const moreInfoToggleHanlder = () => {
    setShowMoreInfo((prevState) => {
      if (prevState === MoreInfoStatus.DEFAULT) {
        return MoreInfoStatus.PRIMARY;
      }
      return MoreInfoStatus.DEFAULT;
    });
  };
  return (
    <div>
      <div className={styles.titleHeader}>
        <p className={styles.title}>{title}</p>
        {moreInfo
          ? (
            <div
              onClick={moreInfoToggleHanlder}
              className={`library-shaw-icon-new ${styles.infoIcon}`}
            >
              <i className="icon-new-Information-3" />
            </div>
          )
          : null}
      </div>
      {showMoreInfo === MoreInfoStatus.PRIMARY
      && !((location?.pathname?.split('/').includes('requested')
    || location?.pathname?.split('/').includes('assigned')))
           && (
             <MoreInfo
               mainContent
               text={moreInfo}
               hideBanner={closeMoreInfoBanner}
               accountSection={accountSection}
               setShowInviteUserModal={setShowInviteUserModal}
             />
           )}
    </div>
  );
};

export default MoreInfoHeader;
