/* eslint-disable import/prefer-default-export */
import { API, Auth } from 'aws-amplify';
import { CourseAccessType } from '../../../API';

interface MetricsQuery {
  from: string,
  to: string,
  enterpriseId: string,
  groupIds: Array<string>,
  userId: string,
  tags: Array<string>,
  courseId: string,
  courseAccessType: CourseAccessType
}

const getHeaders = async () => {
  const currentSession = await Auth.currentSession();
  const accessToken = currentSession?.getIdToken()?.getJwtToken();
  return {
    Authorization: `Bearer ${accessToken}`,
  };
};

const fetchMetrics = async (path: string, query:Partial<MetricsQuery>) :Promise<any> => {
  const api = 'enterpriseappmetrics';
  const headers = await getHeaders();
  const response = await API.get(api, path, {
    headers,
    queryStringParameters: query,
  });
  return response;
};

export { fetchMetrics };
