import { DataStore } from 'aws-amplify';
import { Course } from '../../../models';
import { getCourseById } from '../../GraphQL/Services';

const fetchCourseById = async (courseId:string) : Promise<Course | null> => {
  try {
    const course = await DataStore.query(Course, courseId);
    if (course) return course;
    return await getCourseById(courseId);
  } catch (error) {
    return null;
  }
};

export {
  // eslint-disable-next-line import/prefer-default-export
  fetchCourseById,
};
