const accountTabs = {
  activeTab: '',
  tabData: [
    {
      name: 'Invites',
      route: '/admin/accounts',
    },
    {
      name: 'Learners',
      route: '/admin/accounts/users',
    },
    {
      name: 'Groups',
      route: '/admin/accounts/groups',
    },
    {
      name: 'Learner Permissions',
      route: '/admin/accounts/permissions',
    },
    // {
    //   name: 'Import Logs',
    //   route: '/admin/accounts/job-listing',
    // },
  ],
};

export default accountTabs;
