// extracted by mini-css-extract-plugin
export var UsersAndGroupsContainer = "PerformanceUsersGroupsTab-module--UsersAndGroupsContainer--E6q_F";
export var tabs = "PerformanceUsersGroupsTab-module--tabs--1LAUq";
export var active = "PerformanceUsersGroupsTab-module--active--1Ij2H";
export var infoIcon = "PerformanceUsersGroupsTab-module--infoIcon--3lin_";
export var topContainer = "PerformanceUsersGroupsTab-module--topContainer--1t2jK";
export var monthlyActiveLearner = "PerformanceUsersGroupsTab-module--monthlyActiveLearner--2b4hW";
export var learnerMonth = "PerformanceUsersGroupsTab-module--learnerMonth--1T2t8";
export var monthlyLearnerCountImage = "PerformanceUsersGroupsTab-module--monthlyLearnerCountImage--35xHd";
export var userDataContainer = "PerformanceUsersGroupsTab-module--userDataContainer--3RI5E";
export var counAndImageContainer = "PerformanceUsersGroupsTab-module--counAndImageContainer--3kHAK";
export var dataTitle = "PerformanceUsersGroupsTab-module--dataTitle--18rd6";
export var calendarImagePlacehoder = "PerformanceUsersGroupsTab-module--calendarImagePlacehoder--12OZb";
export var dataValue = "PerformanceUsersGroupsTab-module--dataValue--2rxQT";
export var dataValueContainer = "PerformanceUsersGroupsTab-module--dataValueContainer--1nLXo";
export var title = "PerformanceUsersGroupsTab-module--title--dxrK1";
export var usersCount = "PerformanceUsersGroupsTab-module--usersCount--2npi1";
export var usersCountHeading = "PerformanceUsersGroupsTab-module--usersCountHeading--66oH3";
export var userContainer = "PerformanceUsersGroupsTab-module--userContainer--3pYmY";
export var userImage = "PerformanceUsersGroupsTab-module--userImage--2LXNK";
export var userName = "PerformanceUsersGroupsTab-module--userName--wWhnr";
export var attendedData = "PerformanceUsersGroupsTab-module--attendedData--FoNBt";
export var activeUsersContainer = "PerformanceUsersGroupsTab-module--activeUsersContainer--J-a6a";
export var activeDescription = "PerformanceUsersGroupsTab-module--activeDescription--35gtR";
export var durationFilter = "PerformanceUsersGroupsTab-module--durationFilter--33cGm";
export var durationFilterExport = "PerformanceUsersGroupsTab-module--durationFilterExport--1AQiV";
export var durationFilterActive = "PerformanceUsersGroupsTab-module--durationFilterActive--20LLp";
export var titleAndFilters = "PerformanceUsersGroupsTab-module--titleAndFilters--21iGE";
export var filterContainer = "PerformanceUsersGroupsTab-module--filterContainer--27wo_";
export var activeUserGraph = "PerformanceUsersGroupsTab-module--activeUserGraph--3NtE5";
export var filterText = "PerformanceUsersGroupsTab-module--filterText--3b5ML";
export var filterTextExport = "PerformanceUsersGroupsTab-module--filterTextExport--3CX04";
export var dateFilterContainer = "PerformanceUsersGroupsTab-module--dateFilterContainer--1hbv2";
export var alignmentContainer = "PerformanceUsersGroupsTab-module--alignmentContainer--2Bffu";
export var buttonTextForFilter = "PerformanceUsersGroupsTab-module--buttonTextForFilter--2E8ia";
export var duration = "PerformanceUsersGroupsTab-module--duration--1nyq_";
export var nextPrevContainer = "PerformanceUsersGroupsTab-module--nextPrevContainer--3kT90";
export var userPerformanceContainer = "PerformanceUsersGroupsTab-module--userPerformanceContainer--1hJ3Y";
export var userPerformanceTitle = "PerformanceUsersGroupsTab-module--userPerformanceTitle--33q9P";
export var searchAndFilterContainer = "PerformanceUsersGroupsTab-module--searchAndFilterContainer--31DHW";
export var searchInputContainer = "PerformanceUsersGroupsTab-module--searchInputContainer--12pKo";
export var searchInput = "PerformanceUsersGroupsTab-module--searchInput--15g7m";
export var searchIconContainer = "PerformanceUsersGroupsTab-module--searchIconContainer--3DHhN";
export var dropdownMenu = "PerformanceUsersGroupsTab-module--dropdownMenu--tec5t";
export var eachMenuData = "PerformanceUsersGroupsTab-module--eachMenuData--2bzrC";
export var tooltipText = "PerformanceUsersGroupsTab-module--tooltipText--2fiUy";
export var rangeContainer = "PerformanceUsersGroupsTab-module--rangeContainer--1o9MQ";
export var resultsAndPagesContainer = "PerformanceUsersGroupsTab-module--resultsAndPagesContainer--3fKB_";
export var currentPage = "PerformanceUsersGroupsTab-module--currentPage--3QVMD";
export var resultTotal = "PerformanceUsersGroupsTab-module--resultTotal--3cuHA";
export var learnerLink = "PerformanceUsersGroupsTab-module--learnerLink--16lct";
export var paginationContainer = "PerformanceUsersGroupsTab-module--paginationContainer--ZW5fe";
export var paginationText = "PerformanceUsersGroupsTab-module--paginationText--2C_fX";
export var tableTitle = "PerformanceUsersGroupsTab-module--tableTitle--3ZL3W";
export var nameButton = "PerformanceUsersGroupsTab-module--nameButton--7F1Fs";
export var actionButton = "PerformanceUsersGroupsTab-module--actionButton--33fks";
export var table = "PerformanceUsersGroupsTab-module--table--3Ic8_";
export var dropDownArrow = "PerformanceUsersGroupsTab-module--dropDownArrow--31BrY";