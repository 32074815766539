/* eslint-disable max-lines */
import React, { useState, useEffect, useContext } from 'react';
import dayjs from 'dayjs';
import { Progress, Tooltip } from 'antd';
import AdminContext from '../../../context/Admin/AdminContext';
import TableComponent from '../../Shared/Table/TableComponent';
import * as styles from '../../../styles/admin/PerformanceUsersGroupsTab.module.scss';
import { fetchMetrics } from '../Services/MetricsApiService';
import { getEnrolledCourses, mergeEnrollmentsReport } from '../Services/UsersAndGroupsTabService';
import { getGroupsByEnterpriseID } from '../../GraphQL/Services';
import { CourseAccessType } from '../../../models';
import { truncateText } from '../../../services/utils';

const months = [
  'January', 'February', 'March', 'April', 'May', 'June', 'July',
  'August', 'September', 'October', 'November', 'December',
];

const UsersAndGroupsTabView = () => {
  const { userData, profileInfo, companyInfo } = useContext(AdminContext);

  const [groupList, setGroupList] = useState([]);
  const [tableLoader, setTableLoader] = useState(true);
  const [monthlyActiveLearners, setMonthlyActiveLearners] = useState(null);
  const [learnersOfMonthData, setLearnersOfMonthData] = useState(null);
  const [selectedGroupNames, setSelectedGroupNames] = useState([]);
  const [usersInSelectedDateRange, setUsersInSelectedDateRange] = useState([]);
  const [enrolledCourses, setEnrolledCourses] = useState([]);
  const [tableData, setTableData] = useState({
    base: [],
    allUserReports: [],
    expanded: [],
    csvData: [],
  });
  const [statusFilter, setStatusFilter] = useState('active');
  const [activeTab, setActiveTab] = useState('upskillist');

  const getTooltipText = () => {
    if (activeTab === 'upskillist') {
      return 'Course Completion is marked as complete only when all of the end of module assignments have been passed';
    }
    return 'Course Completion is marked as complete only when at least 30% of the video has been watched';
  };

  const fetchEnrollmentsReport = async (from, to, courseAccessType) => {
    const enterpriseId = profileInfo?.enterpriseID;
    const path = '/courseregistrationstats';
    try {
      const response = await fetchMetrics(path, {
        courseAccessType,
        enterpriseId,
        ...(from && { from: dayjs(from).toISOString() }),
        ...(to && { to: dayjs(to).toISOString() }),
      });
      const mergedResponse = await mergeEnrollmentsReport(response);
      return getEnrolledCourses(mergedResponse);
    } catch (e) {
      return [];
    }
  };

  const fetchEnrolledCourses = async (usersPerformance, from = '', to = '', courseAccessType = CourseAccessType.PUBLIC) => {
    const baseTableData = [];
    const expandedTableData = [];
    const csvData = [];
    if (usersPerformance?.length > 0) {
      const enrolledCoursesInfo = await fetchEnrollmentsReport(from, to, courseAccessType);
      for (let i = 0; i < usersPerformance?.length; i += 1) {
        const currentUserPerformance = usersPerformance[i];
        const userPerformanceData = currentUserPerformance?.attributes;
        const coursesEnrolled = userPerformanceData?.coursesEnrolled;
        const coursesCompleted = userPerformanceData?.coursesCompleted;
        const tasksCompleted = userPerformanceData?.tasksCompleted;
        const taskProgress = tasksCompleted;
        const completion = Math.floor((coursesCompleted / coursesEnrolled) * 100);
        const lastActive = currentUserPerformance?.lastActive;
        const data = {
          key: currentUserPerformance?.userId,
          id: currentUserPerformance?.userId,
          name: currentUserPerformance?.name,
          coursesEnrolled,
          taskProgress,
          coursesCompleted,
          completion: Number.isNaN(completion) ? 0 : completion,
          lastActive,
          groupIds: currentUserPerformance?.groupIds,
          userId: currentUserPerformance?.userId,
          enabled: currentUserPerformance?.enabled,
        };
        const enrollmentReports = enrolledCoursesInfo.filter((
          course,
        ) => course?.userId === currentUserPerformance?.userId);

        if (enrollmentReports?.length > 0) {
          for (let j = 0; j < enrollmentReports.length; j += 1) {
            const enrollment = enrollmentReports[j];
            const courseName = enrollment?.courseName;
            const lessons = enrollment?.lessons;
            const assignments = enrollment?.assignments;
            const modules = enrollment?.modules;
            const completions = enrollment?.completions;
            const courseId = enrollment?.courseId;
            const enrollmentData = {
              key: enrollment?.key,
              id: enrollment?.key,
              courseName,
              courseId,
              lessons: `${lessons}`,
              assignments: `${assignments}`,
              modules: `${modules}`,
              courseCompletion: completions,
              userId: enrollment?.userId,
              moduleOneScore: enrollment.moduleOneScore,
              moduleTwoScore: enrollment.moduleTwoScore,
              moduleThreeScore: enrollment.moduleThreeScore,
              moduleFourScore: enrollment.moduleFourScore,
              courseResult: enrollment.courseResult,
            };
            expandedTableData.push(enrollmentData);
            if (!j) {
              csvData.push({
                ...data,
                ...enrollmentData,
                courseCompletion: `${enrollmentData.courseCompletion}%`,
              });
            } else {
              csvData.push({
                ...enrollmentData,
                courseCompletion: `${enrollmentData.courseCompletion}%`,
              });
            }
          }
        } else {
          csvData.push(data);
        }
        baseTableData.push(data);
      }
    }
    return {
      baseTableData,
      allUserReports: expandedTableData,
      expandedTableData: expandedTableData.sort((a, b) => b.courseCompletion - a.courseCompletion),
      csvData,
    };
  };

  const getTableData = (data, isFilterApplied = true, filterValue = 'active') => {
    if (isFilterApplied) {
      const showOnlyActiveUsers = filterValue === 'active';
      return data?.filter((user) => user?.enabled === showOnlyActiveUsers);
    }
    return data;
  };

  const fetchUsersData = async (usersPerformance, courseAccessType = CourseAccessType.PUBLIC) => {
    const groups = await getGroupsByEnterpriseID(profileInfo?.enterpriseID);
    if (groups?.length > 0) {
      const filteredGroups = groups.filter((group) => !group?.deletedAt);
      setGroupList(filteredGroups);
      const allGroupNames = filteredGroups.map((group) => group?.groupName);
      setSelectedGroupNames(allGroupNames);
    }
    const {
      baseTableData,
      allUserReports,
      expandedTableData,
      csvData,
    } = await fetchEnrolledCourses(usersPerformance, '', '', courseAccessType);
    setUsersInSelectedDateRange(baseTableData);
    setTableData({
      base: baseTableData,
      allUserReports,
      expanded: expandedTableData,
      csvData,
    });
    setTableLoader(false);
  };

  const mergeResponse = (response) => {
    const dataMap = new Map();
    const includedData = [];
    response?.data.forEach((data) => {
      const id = data?.relationships?.user?.data?.id;
      dataMap.set(id, data);
    });
    response?.included.forEach((included) => {
      const id = included?.id;
      const dataMapAttributes = dataMap.get(id);
      if (dataMapAttributes) {
        const groupIds = included?.relationships?.groups?.data?.length > 0
          ? included.relationships.groups.data.map((group) => group?.id) : [];
        const updatedData = {
          userId: included?.id,
          name: included?.attributes?.name,
          attributes: dataMapAttributes?.attributes,
          lastActive: included?.attributes?.lastActive,
          groupIds,
          month: months[dayjs().get('month')],
          enabled: included?.attributes?.enabled,
        };
        includedData.push(updatedData);
      }
    });
    return includedData;
  };

  const getUsersPerformance = async (from = '', to = '', courseAccessType = CourseAccessType.PUBLIC) => {
    try {
      const enterpriseId = profileInfo?.enterpriseID;
      const path = '/userperformancestats';
      const query = {
        courseAccessType,
        enterpriseId,
        ...(from && { from: dayjs(from).toISOString() }),
        ...(to && { to: dayjs(to).toISOString() }),
      };
      const usersPerformanceResponse = await fetchMetrics(path, query);
      const usersPerformance = mergeResponse(usersPerformanceResponse);
      return usersPerformance;
    } catch (error) {
      return [];
    }
  };

  const getTopThreeLearnersOfTheMonth = (learnersOfTheMonth) => {
    const sortedLearnersBasedOnTasksCompleted = learnersOfTheMonth.sort(
      (a, b) => b?.attributes?.tasksCompleted - a?.attributes?.tasksCompleted,
    );
    return sortedLearnersBasedOnTasksCompleted.slice(0, 3);
  };

  const fetchLearnersOfTheMonth = async () => {
    try {
      const enterpriseId = profileInfo?.enterpriseID;
      const path = '/userperformancestats';
      const from = dayjs().startOf('month').toISOString();
      const to = dayjs().toISOString();
      const learnersOfTheMonthResponse = await fetchMetrics(path, { enterpriseId, from, to });
      const learnersOfTheMonth = mergeResponse(learnersOfTheMonthResponse);
      if (learnersOfTheMonth?.length > 0) {
        const topThreeLearners = getTopThreeLearnersOfTheMonth(learnersOfTheMonth);
        setLearnersOfMonthData(topThreeLearners);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchMonthlyActiveLearners = async () => {
    try {
      const enterpriseId = profileInfo?.enterpriseID;
      const path = `/enterpriseperformanceinsights/${enterpriseId}`;
      const from = dayjs().startOf('month').toISOString();
      const to = dayjs().toISOString();
      const response = await fetchMetrics(path, { from, to });
      setMonthlyActiveLearners(response?.data?.attributes);
    } catch (error) {
      console.log(error);
    }
  };

  const getCourseAccessType = (tab) => (tab === 'upskillist' ? CourseAccessType.PUBLIC : CourseAccessType.PRIVATE);

  const getUsersInSelectedGroups = (groupNames, users, status = statusFilter) => {
    const selectedGroups = groupList.filter((item) => groupNames.includes(item?.groupName));
    const selectedGroupIds = selectedGroups?.length > 0
      ? selectedGroups.map((group) => group?.id) : [];
    const usersInSelectedGroup = users.filter((user) => user.groupIds.some((
      groupId,
    ) => selectedGroupIds.includes(groupId)));

    const applyFilter = status !== 'all';
    const filteredUsers = getTableData(usersInSelectedGroup, applyFilter, status);
    return filteredUsers;
  };

  const filterByStatus = (status) => {
    setStatusFilter(status);
    if (selectedGroupNames?.length === groupList?.length) {
      const applyFilter = status !== 'all';
      const filteredUsers = getTableData(usersInSelectedDateRange, applyFilter, status);
      return filteredUsers;
    }

    return getUsersInSelectedGroups(selectedGroupNames, usersInSelectedDateRange, status);
  };

  const filterByGroup = (groupNames) => {
    setSelectedGroupNames(groupNames);
    if (groupNames?.length === groupList?.length) {
      const applyFilter = statusFilter !== 'all';
      const filteredUsers = getTableData(usersInSelectedDateRange, applyFilter, statusFilter);
      return filteredUsers;
    }

    return getUsersInSelectedGroups(groupNames, usersInSelectedDateRange);
  };

  const filterByDateHandler = async (selectedDate) => {
    setTableLoader(true);
    try {
      let expandedTableData = [];
      let filteredUsers;
      if (selectedDate?.startDate.isSame(selectedDate?.endDate)) {
        const users = tableData?.base;
        const applyFilter = statusFilter !== 'all';
        filteredUsers = getTableData(users, applyFilter, statusFilter);
        expandedTableData = tableData?.allUserReports;
      } else {
        const from = selectedDate?.endDate;
        const to = selectedDate?.startDate;
        const courseAccessType = getCourseAccessType(activeTab);
        const usersPerformanceMap = await getUsersPerformance(from, to, courseAccessType);
        const data = await fetchEnrolledCourses(usersPerformanceMap, from, to, courseAccessType);
        const users = data.baseTableData;
        const applyFilter = statusFilter !== 'all';
        filteredUsers = getTableData(users, applyFilter, statusFilter);
        expandedTableData = data.expandedTableData;
      }
      setTableData((prev) => ({
        ...prev,
        expanded: expandedTableData,
      }));
      setUsersInSelectedDateRange(filteredUsers);
      if (selectedGroupNames?.length === groupList?.length) {
        return filteredUsers;
      }
      return getUsersInSelectedGroups(selectedGroupNames, filteredUsers);
    } catch (error) {
      return [];
    } finally {
      setTableLoader(false);
    }
  };

  const filterEnrolledCoursesReportsByUserId = (userId) => {
    const filteredData = tableData?.expanded.filter((user) => user?.userId === userId);
    setEnrolledCourses(filteredData);
  };

  const loadDependencies = async (courseAccessType = CourseAccessType.PUBLIC) => {
    const usersPerformanceMap = await getUsersPerformance('', '', courseAccessType);
    fetchUsersData(usersPerformanceMap, courseAccessType);
  };

  const toggleActiveTab = (tab) => {
    if (tab !== activeTab) {
      setActiveTab(tab);
      setStatusFilter('active');
      setTableLoader(true);
      const courseAccessType = getCourseAccessType(tab);
      loadDependencies(courseAccessType);
    }
  };

  useEffect(() => {
    if (userData?.sub && profileInfo?.id) {
      loadDependencies();
      fetchMonthlyActiveLearners();
      fetchLearnersOfTheMonth();
    }
  }, [userData, profileInfo?.id]);

  const expandedTableColumns = [
    {
      header: 'Course Name',
      title: 'Course Name',
      dataIndex: 'courseName',
      ...(activeTab === 'upskillist' && { width: 180 }),
    },
  ];

  if (activeTab === 'upskillist') {
    expandedTableColumns.push({
      header: 'Lessons Watched',
      title: 'Lessons Watched',
      dataIndex: 'lessons',
      width: 170,
    },
    {
      header: 'Assignments Completed',
      title: 'Assignments Completed',
      dataIndex: 'assignments',
      width: 150,
    },
    {
      header: 'Modules Passed',
      title: 'Modules Passed',
      dataIndex: 'modules',
      width: 150,
    },
    {
      header: 'Module 1 Score',
      title: 'Module 1 Score',
      dataIndex: 'moduleOneScore',
      width: 150,
    },
    {
      header: 'Module 2 Score',
      title: 'Module 2 Score',
      dataIndex: 'moduleTwoScore',
      width: 150,
    },
    {
      header: 'Module 3 Score',
      title: 'Module 3 Score',
      dataIndex: 'moduleThreeScore',
      width: 150,
    },
    {
      header: 'Module 4 Score',
      title: 'Module 4 Score',
      dataIndex: 'moduleFourScore',
      width: 150,
    },
    {
      header: 'Course Result',
      title: 'Course Result',
      dataIndex: 'courseResult',
      width: 150,
    });
  }

  expandedTableColumns.push(
    {
      header: 'Completion',
      dataIndex: 'courseCompletion',
      width: activeTab === 'upskillist' ? 170 : 300,
      render: (value) => (
        <Progress
          percent={Math.ceil(value)}
          trailColor="#f0f4f7"
          strokeColor="#a4ede2"
          width={150}
        />
      ),
      title: () => (
        <div>
          <span>Completion</span>
          <Tooltip placement="top" title={getTooltipText()}>
            <span style={{ cursor: 'pointer', marginLeft: '6px' }} className="library-shaw-icon-new">
              <i className="icon-new-Information-3" />
            </span>
          </Tooltip>
        </div>
      ),
    },
  );

  const columns = [
    {
      header: 'Learner Name',
      title: 'Learner Name',
      dataIndex: 'name',
      render: (text, record) => (
        <button
          type="button"
          onClick={() => filterEnrolledCoursesReportsByUserId(record?.id)}
          className={styles.nameButton}
        >
          {text}
        </button>
      ),
      onCell: (record) => ({
        onClick: () => filterEnrolledCoursesReportsByUserId(record?.id),
      }),
      width: 130,
    },
    {
      header: 'Courses Enrolled',
      title: 'Courses Enrolled',
      dataIndex: 'coursesEnrolled',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.coursesEnrolled - b.coursesEnrolled,
      onCell: (record) => ({
        onClick: () => filterEnrolledCoursesReportsByUserId(record?.id),
      }),
      width: 150,
    },
    {
      header: 'Task Progress',
      title: () => (
        <div>
          <span>Task Progress</span>
          <Tooltip placement="right" title="Total Tasks Completed">
            <span style={{ cursor: 'pointer', marginLeft: '6px' }} className="library-shaw-icon-new">
              <i className="icon-new-Information-3" />
            </span>
          </Tooltip>
        </div>
      ),
      dataIndex: 'taskProgress',
      render: (taskProgress) => (<p>{`${taskProgress}`}</p>),
      sorter: (a, b) => a.taskProgress - b.taskProgress,
      onCell: (record) => ({
        onClick: () => filterEnrolledCoursesReportsByUserId(record?.id),
      }),
      width: 150,
    },
    {
      header: 'Courses Completed',
      title: 'Courses Completed',
      dataIndex: 'coursesCompleted',
      sorter: (a, b) => a.coursesCompleted - b.coursesCompleted,
      onCell: (record) => ({
        onClick: () => filterEnrolledCoursesReportsByUserId(record?.id),
      }),
      width: 150,
    },
    {
      header: 'Last Active',
      title: 'Last Active',
      dataIndex: 'lastActive',
      render: (lastActive) => (lastActive && <p>{dayjs(lastActive).format('DD/MM/YYYY')}</p>),
      sorter: (a, b) => new Date(a.lastActive) - new Date(b.lastActive),
      onCell: (record) => ({
        onClick: () => filterEnrolledCoursesReportsByUserId(record?.id),
      }),
      width: 130,
    },
  ];

  const headers = columns.map((column) => ({ label: column.header, key: column.dataIndex }));

  const expandedTableHeaders = expandedTableColumns.map((
    column,
  ) => ({ label: column.header, key: column.dataIndex }));

  const baseTableData = getTableData(tableData?.base, statusFilter !== 'all', statusFilter);

  const truncatedText = truncateText(companyInfo?.name);

  return (
    <>
      <div className={styles.UsersAndGroupsContainer}>
        <div className={styles.topContainer}>
          <div className={styles.monthlyActiveLearner}>
            <div className={styles.counAndImageContainer}>
              <div>
                <div className={styles.title}>Monthly Active Learners</div>
              </div>
            </div>
            <div className={styles.userDataContainer}>
              <div className={styles.dataValueContainer}>
                <div className={styles.dataValue}>{monthlyActiveLearners?.usersActive}</div>
                <div className={styles.dataTitle}>Active Learners</div>
              </div>
              <div className={styles.dataValueContainer}>
                <div className={styles.dataValue}>{monthlyActiveLearners?.videoTasksCompleted}</div>
                <div className={styles.dataTitle}>Average Classes per learner per week</div>
              </div>
              <div className={styles.dataValueContainer}>
                <div className={styles.dataValue}>{monthlyActiveLearners?.coursesEnrolled}</div>
                <div className={styles.dataTitle}>Total course enrolments</div>
              </div>
            </div>
          </div>
          <div className={styles.learnerMonth}>
            <div className={styles.title}>
              <span>Learners of the month</span>
            </div>
            {learnersOfMonthData?.length > 0 && learnersOfMonthData.map((learner) => (
              <div key={learner?.userId} className={styles.userContainer}>
                <div>
                  <div className={styles.userName}>{learner?.name}</div>
                  <div className={styles.attendedData}>
                    {`${learner?.attributes?.tasksCompleted} tasks completed in ${learner?.month}`}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <p className={styles.userPerformanceTitle}>Learner Performance</p>
        {companyInfo?.scormCloudAppID && (
        <div className={styles.tabs}>
          <button
            type="button"
            id="admin-performance-learners-and-groups-switch-tab-public-courses"
            className={activeTab === 'upskillist' ? styles.active : ''}
            onClick={() => toggleActiveTab('upskillist')}
          >
            Upskillist Courses
          </button>
          <button
            type="button"
            id="admin-performance-learners-and-groups-switch-tab-private-courses"
            className={activeTab === 'private' ? styles.active : ''}
            onClick={() => toggleActiveTab('private')}
          >
            {`${truncatedText}'s Courses`}
          </button>
        </div>
        )}
        <TableComponent
          expandableTableOptions={{
            data: enrolledCourses,
            isExpandable: true,
            headers: [...headers, ...expandedTableHeaders],
            columns: expandedTableColumns,
            csvData: tableData?.csvData,
            renderData: (record) => filterEnrolledCoursesReportsByUserId(record.id),
            isRowExpandable: (record) => !!record?.coursesEnrolled,
            scrollable: true,
          }}
          globalSearchPlaceholder="Search by name"
          displayColumns={columns}
          allData={baseTableData}
          tableLoader={tableLoader}
          headers={headers}
          fileName="User Performance Report.csv"
          performanceTabOptions={{
            selectedStatusFilter: statusFilter,
            setSelectedStatusFilter: setStatusFilter,
            resetData: activeTab,
          }}
          filters={{
            group: {
              name: 'Group',
              type: 'checkbox',
              options: groupList.map((group) => group.groupName),
              groupList,
              filterHandler: filterByGroup,
            },
            status: {
              name: 'Status',
              type: 'dropdown',
              filterHandler: filterByStatus,
            },
            datetime: {
              name: 'Date',
              type: 'date',
              filterHandler: filterByDateHandler,
            },
          }}
          domIDs={{
            Download: 'admin-performance-learners-and-groups-download',
          }}
        />
      </div>
    </>
  );
};
export default UsersAndGroupsTabView;
