import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import HeaderComponent from './Header';
import SidebarComponent from './Sidebar';
import MoreInfoHeader from './MoreInfoHeader';
import {
  fetchUserBySub,
} from '../DataStore/Services';
import TabContainer from './TabContainer';
import * as styles from '../../styles/admin/Account.module.scss';
import { getCurrentUser } from '../Shared/Services/AuthService';
import { Role } from '../../models';
import AuthorizationWrapper from './AuthorizationWrapper';
import AdminContext from '../../context/Admin/AdminContext';

const Layout = ({
  tabs, Component, title,
  identityProviderClient, activeTab,
  location, courseDetailsCtaText, courseDetailsCtaHandler,
  moreInfo,
  accountSection,
}) => {
  const [tabList, setTabList] = useState(null);
  const [showInviteUserModal, setShowInviteUserModal] = useState(false);
  const { companyInfo } = useContext(AdminContext);

  const filterTabs = async () => {
    const signedInUser = await getCurrentUser();
    const subId = signedInUser?.attributes?.sub;
    const activeUserId = signedInUser.attributes?.['custom:active_user'];
    const userDetails = await fetchUserBySub(subId, activeUserId);
    const list = { ...tabs };
    if (companyInfo && userDetails) {
      if (list && Object.values(list)?.length !== 0) {
        if (!userDetails?.roles.every((role) => [Role.ADMIN, Role.OWNER].includes(role))) {
          const filteredList = list?.tabData?.filter((data) => data.name !== 'Learner Permissions');
          list.tabData = [...filteredList];
        }
        if (!companyInfo?.scormCloudAppID) {
          const filteredList = list?.tabData?.filter((data) => data.name !== 'Upload Course');
          list.tabData = [...filteredList];
        }
      }
    }
    setTabList(list);
  };

  useEffect(() => {
    filterTabs();
  }, [companyInfo]);

  return (
    <AuthorizationWrapper>
      <HeaderComponent />
      <div style={{ display: 'flex' }}>
        <SidebarComponent />
        <div className={styles.accountWrapper}>
          {title
            && (
              <MoreInfoHeader
                title={title}
                moreInfo={moreInfo}
                location={location}
                accountSection={accountSection}
                setShowInviteUserModal={setShowInviteUserModal}
              />
            )}
          {(tabList || tabs)
            && (
              <TabContainer
                tabs={tabList || tabs}
                activeTab={activeTab}
              />
            )}

          <Component
            identityProviderClient={identityProviderClient}
            course={location?.state?.course}
            courseDetailsCtaHandler={courseDetailsCtaHandler}
            courseDetailsCtaText={courseDetailsCtaText}
            location={location}
            showInviteUserModal={showInviteUserModal}
            setShowInviteUserModal={setShowInviteUserModal}
          />
        </div>
      </div>
    </AuthorizationWrapper>
  );
};

Layout.propTypes = {
  tabs: PropTypes.objectOf(PropTypes.any).isRequired,
  Component: PropTypes.elementType.isRequired,
  title: PropTypes.string.isRequired,
  identityProviderClient: PropTypes.objectOf(PropTypes.any).isRequired,
  activeTab: PropTypes.string.isRequired,
  location: PropTypes.objectOf(PropTypes.any),
  courseDetailsCtaText: PropTypes.string,
  courseDetailsCtaHandler: PropTypes.func,
  moreInfo: PropTypes.string,
  accountSection: PropTypes.bool,
};

Layout.defaultProps = {
  location: {},
  courseDetailsCtaText: '',
  courseDetailsCtaHandler: null,
  moreInfo: '',
  accountSection: false,
};

export default Layout;
